import React from 'react'
import img1 from '../../assets/imeges/100.jpg'
import img2 from '../../assets/imeges/200.jpg'
export default function InnerText() {
    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-6">
                        <img src={img1} className='w-100' alt="" />
                    </div>
                    <div className="col-md-6">
                        <h2 className='text-main'>About ELDEEB:</h2>
                        <p className='fs-3 text-muted'>Welcome to <span className='text-main'>ELDEEB</span>, your trusted partner in financial success. We are a team of dedicated public accountants and financial advisors committed to providing personalized and strategic financial solutions to individuals and businesses alike.</p>
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-md-6">
                        <h2 className='text-main'>Our Mission:</h2>
                        <p className='fs-3 text-muted'>At <span className='text-main'>ELDEEB</span>, our mission is to empower our clients to achieve their financial goals through expert guidance, innovative solutions, and unwavering integrity. We strive to be a beacon of financial clarity in an ever-evolving economic landscape.</p>
                    </div>
                    <div className="col-md-6">
                        <img src={img2} className='w-100' alt="" />
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-md-6">
                        <img src={img1} className='w-100' alt="" />
                    </div>
                    <div className="col-md-6">
                        <h2 className='text-main'>Expertise You Can Trust:</h2>
                        <p className='fs-3 text-muted'>With a combined experience of 25 years in the industry, our team of seasoned professionals brings a wealth of knowledge and expertise to every client engagement. We specialize in  tax planning, financial management, audit services, etc., ensuring that our clients receive comprehensive and tailored solutions.</p>
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-md-6">
                        <h2 className='text-main'>Client-Centric Approach:</h2>
                        <p className='fs-3 text-muted'>At the heart of our philosophy is a deep commitment to understanding the unique needs and aspirations of each client. We believe in building long-lasting relationships based on trust, open communication, and a personalized approach to financial planning.</p>
                    </div>
                    <div className="col-md-6">
                        <img src={img2} className='w-100' alt="" />
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-md-6">
                        <h2 className='text-main'>Services We Offer:</h2>
                        <ul className='fs-4 text-muted'>
                            <li><span className='text-main'>Tax Planning and Compliance:</span> Navigate the complexities of tax regulations with confidence and optimize your financial position.</li>
                            <li><span className='text-main'>Financial Advisory Services:</span>  Receive strategic guidance to make informed decisions that align with your short-term and long-term financial objectives.</li>
                            <li><span className='text-main'>Audit and Assurance:</span>  Ensure the accuracy and reliability of your financial information through our meticulous audit and assurance services.</li>
                            <li><span className='text-main'>Business Consulting:</span> Drive growth and efficiency in your business with our tailored consulting services.</li>
                        </ul>
                    </div>
                    <div className="col-md-6 border-start">
                    <h2 className='text-main'>Why Choose ELDEEB:</h2>
                        <ul className='fs-4 text-muted'>
                            <li><span className='text-main'>Personalized Solutions: </span> Navigate the complexities of tax regulations with confidence and optimize your financial position.</li>
                            <li><span className='text-main'>Commitment to Excellence:</span>  Receive strategic guidance to make informed decisions that align with your short-term and long-term financial objectives.</li>
                            <li><span className='text-main'>Proactive Approach: </span>  Ensure the accuracy and reliability of your financial information through our meticulous audit and assurance services.</li>
                        </ul>
                    </div>
                    <p className='fs-3 text-muted'>Join us on the journey to financial success. At <span className='text-main'>ELDEEB</span>, we don't just crunch numbers; we build futures.</p>
                </div>
            </div>
        </>
    )
}
