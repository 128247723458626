import React from 'react'
import InnerText from '../InnerText/InnerText'

export default function About() {
  return (
    <>
    <h1 className='text-center text-main'>AbouUs:</h1>
    <InnerText/>
    
    </>
  )
}
