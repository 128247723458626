import React from 'react'
import Slider from "react-slick";
import img1 from '../../assets/imeges/slide1_1.jpg'
import img2 from '../../assets/imeges/slide1.jpg'
import img3 from '../../assets/imeges/slide2.jpg'
import img4 from '../../assets/imeges/slide3.jpg'
export default function MainSlider() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay:true,
        autoplaySpeed:3000
    };
    return (
        <>
            <Slider {...settings}>
                <div>
                    <img src={img1} className='w-100 vh' alt="" />
                </div>
                <div>
                    <img src={img2} alt=""  className='w-100 vh'/>
                </div>
                <div>
                    <img src={img3} alt="" className='w-100 vh'/>
                </div>
                <div>
                    <img src={img4} alt="" className='w-100 vh'/>
                </div>

            </Slider>

        </>
    )
}
