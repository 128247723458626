import React from 'react'
import ImgSer from '../../assets/imeges/images.jpg'
import ImgSer1 from '../../assets/imeges/image2.jpg'
import ImgSer2 from '../../assets/imeges/image3.jpg'
import ImgSer3 from '../../assets/imeges/image4.jpg'
export default function Services() {
  return (
    <>
      <div className="container p-5">
        <div className="row gy-5">
          <div className="col-md-6 ">
            <div className="card " >
              <div className=" overflow-hidden">
                <img src={ImgSer} className="card-img-top SerImg" alt="TaxPlanning" />
              </div>
              <div className="card-body ">
                <h5 className="card-title text-main fw-bolder">Tax Planning and Compliance:</h5>
                <p className="card-text">Navigate the complexities of tax regulations with confidence and optimize your financial position.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 ">
          <div className="card " >
              <div className=" overflow-hidden">
                <img src={ImgSer3} className="card-img-top SerImg" alt="TaxPlanning" />
              </div>
              <div className="card-body ">
                <h5 className="card-title text-main fw-bolder">Business Consulting:</h5>
                <p className="card-text">Drive growth and efficiency in your business with our tailored consulting services.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
          <div className="card " >
              <div className=" overflow-hidden">
                <img src={ImgSer2} className="card-img-top SerImg" alt="TaxPlanning" />
              </div>
              <div className="card-body ">
                <h5 className="card-title text-main fw-bolder">Audit and Assurance:</h5>
                <p className="card-text">Ensure the accuracy and reliability of your financial information through our meticulous audit and assurance services.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
          <div className="card " >
              <div className=" overflow-hidden">
                <img src={ImgSer1} className="card-img-top SerImg" alt="TaxPlanning" />
              </div>
              <div className="card-body ">
                <h5 className="card-title text-main fw-bolder">Financial Advisory Services:</h5>
                <p className="card-text">Receive strategic guidance to make informed decisions that align with your short-term and long-term financial objectives.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
