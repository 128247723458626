import React from 'react'
import logo from '../../assets/imeges/WhatsApp_Image_2024-02-02_at_23.08.53_6f259082-removebg-preview.png'
export default function Footer() {
  return (
    <>

    <div className="foot p-5">
      <img src={logo} className='w-50' alt="" />
    </div>
    </>
  )
}
