import React from 'react'
import errimg from '../../assets/imeges/vecteezy_404-landing-page_6549647.svg'
export default function Notfound() {
  return (
    <>
    <img src={errimg} className='w-100' alt="" />
    
    </>
  )
}
