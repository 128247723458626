import React from 'react'
import Navbar from './components/Navbar/Navbar'
import MainSlider from './components/MainSlider/MainSlider'
import {RouterProvider,createBrowserRouter}from 'react-router-dom'
import Mainlayout from './components/Layouts/Mainlayout'
import Home from './components/Home/Home'
import About from './components/About/About'
import Services from './components/Services/Services'
import Contact from './components/Contact/Contact'
import Notfound from './components/Notfound/Notfound'
import { Offline, Online } from "react-detect-offline";

export default function App() {
  let routes=createBrowserRouter([
    {path:'/',element: <Mainlayout />,children:[ 
      {index:true,element:<Home/>},
      {path:'Home',element:<Home/>},
      {path:'About',element:<About/>},
      {path:'Services',element:<Services/>},
      {path:'Contact',element:<Contact/>},
      {path:'*',element:<Notfound/>},
    ]}
  ])
  return (
    <>
    
    
    <RouterProvider router={routes} />
     
    <Offline>
      <div className='offline'>
    <h3>You are offline !!!!!</h3>
      </div>
    </Offline>
    </>
  )
}