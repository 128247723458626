import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function Contact() {
  return (
    <>
    <div className="container">
      <div className="row py-5 border-bottom">
        <h2 className='text-center text-main p-5'>In Egypt:</h2>
        <div className="col-md-6">
          <ul className='list-unstyled'>
            <li><i className="fa-solid fa-phone fs-3 text-main p-3 " />+20 01007188705 </li>
            <li><i className="fa-solid fa-map-location-dot fs-3 text-main p-3" /> X842+M4R, New Degla City, Zahraa El Maadi, Cairo Governorate</li>
            <li><i className="fa-solid fa-envelope fs-3 text-main p-3" /> office.eldeeb@yahoo.com</li>
            <li><i className="fa-brands fa-whatsapp fs-3 text-main p-3" /> +20 01007188705 </li>
          </ul>
        </div>
        <div className="col-md-6 border-start">
        <iframe className='ps-3' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.7894181340716!2d31.30283962475024!3d29.956735222721576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458395c5c32755f%3A0x3e1a2d5ee2770f05!2sSun%20Gates!5e0!3m2!1sar!2seg!4v1708986893637!5m2!1sar!2seg" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

        </div>
      </div>
      <div className="row py-5">
      <h2 className='text-center text-main p-5'>In UAE:</h2>
      <div className="col-md-6">
          <ul className='list-unstyled'>
            <li><i className="fa-solid fa-phone fs-3 text-main p-3 " /> +971 58 863 1599 </li>
            <li><i className="fa-solid fa-map-location-dot fs-3 text-main p-3" /> Dubai,Uae</li>
            <li><i className="fa-solid fa-envelope fs-3 text-main p-3" /> office.eldeeb@yahoo.com</li>
            <li><i className="fa-brands fa-whatsapp fs-3 text-main p-3" /> +971 58 863 1599</li>
          </ul>
        </div>
        <div className="col-md-6 border-start">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.6828148807!2d55.55714842473273!3d25.076280449761054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2z2K_YqNmKIC0g2KfZhNil2YXYp9ix2KfYqiDYp9mE2LnYsdio2YrYqSDYp9mE2YXYqtit2K_YqQ!5e0!3m2!1sar!2seg!4v1708988670578!5m2!1sar!2seg" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />


        </div>
      </div>
    </div>
    </>
  )
}
