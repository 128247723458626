import React from 'react'
import MainSlider from '../MainSlider/MainSlider'
import InnerText from '../InnerText/InnerText'

export default function Home() {
  return (
    <>
    <MainSlider />
    <InnerText/>
    </>
  )
}
